<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">For the net reaction:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="E + SP -> EP + S" />
      </p>

      <p class="mb-3">
        the following elementary reaction has been proposed to be the first step in the reaction
        mechanism:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="E + SP -> ESP" />
      </p>

      <p class="mb-n3">
        a) What is the rate equation (rate law) predicted by this step of the reaction?
      </p>

      <v-radio-group v-model="inputs.rateLaw" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsRateLaw" :key="option.value">
          <v-radio class="d-inline-block mb-3" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-n3">
        b) If the units of the rate are
        <stemble-latex content="$\text{M}\,\text{min}^{-1},$" />
        what are the units associated with the rate constant,
        <stemble-latex content="$\text{k,}$" />
        for this rate law?
      </p>

      <v-radio-group v-model="inputs.units" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsUnits" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        c) Write an elementary reaction that follows the first elementary reaction that would
        complete this mechanism.
      </p>

      <chemical-notation-input
        v-model="inputs.reaction"
        dense
        :show-note="false"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumericCellEditor from '@/common/components/NumericCellEditor.vue';

export default {
  name: 'Question472',
  components: {
    NumericCellEditor,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rateLaw: null,
        units: null,
        reaction: null,
      },
      optionsRateLaw: [
        {
          expression:
            '$\\text{rate}=\\displaystyle{\\frac{\\text{k}_1\\,\\text{[E]}\\,\\text{[SP]}}{\\text{k}_2\\,\\text{[EP]}\\,\\text{[S]}}}$',
          value: 'fullRoverP',
        },
        {
          expression: '$\\text{rate}=\\text{k}\\,\\text{[E]}\\,\\text{[SP]}$',
          value: 'fwd',
        },
        {
          expression:
            '$\\text{rate}=\\displaystyle{\\frac{\\text{k}_1\\,\\text{[E]}\\,\\text{[SP]}}{\\text{k}_2\\,\\text{[ESP]}}}$',
          value: 'RoverP',
        },
        {
          expression: '$\\text{More information needed}$',
          value: 'moreInfoNeeded',
        },
      ],
      optionsUnits: [
        {expression: '$\\text{M}^2\\,\\text{min}^{-1}$', value: 'M2perMin'},
        {expression: '$\\text{M}\\,\\text{min}^{-1}$', value: 'MperMin'},
        {expression: '$\\text{min}^{-1}$', value: 'perMin'},
        {expression: '$\\text{M}^{-1}\\,\\text{min}^{-1}$', value: 'perMperMin'},
        {expression: '$\\text{M}^{-2}\\,\\text{min}^{-1}$', value: 'perM2perMin'},
        {expression: '$\\text{min}\\,\\text{M}^{-1}$', value: 'minPerM'},
      ],
    };
  },
};
</script>
